/* eslint-disable */
import * as React from 'react';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { rem, rgba } from 'polished';
import {
  FlexCol,
  ZemplinLoaderWrapper,
  FlexRow,
  CorporateCategoryHeader as CategoryHeader,
  FlexRowCenterVertical,
  DsiViewSwitcher as ViewSwitcher,
  DsiProductList as ProductList,
  DsiProductTableList as ProductTableList,
  DsiPagination as Pagination,
  DsiProductFilter as ProductFilter,
  IconImg,
  TextP,
  DsiSvgIcon,
  DsiIconType,
  DsiCategoryFilter as CategoryFilter,
  DsiActiveFilters as ActiveFilters,
  DsiResetFilter as ResetFilter,
} from 'eshop-defaults';
import {
  DSI_VAT_VIEW_COOKIE,
  DSI_VOC_VIEW_COOKIE,
  JBL_PRODUCT_VIEW_COOKIE,
} from '../../containers/App/constants';
import {
  arrayToObject,
  prefixUrl,
  prop,
  setLangCookie,
  setProductViewCookie,
  setVatViewCookie,
  setVocViewCookie,
} from '../../utilities';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { useLocalStorage } from '../../utilities/hooks';
import { getCategoryMetaTags } from '../../utilities/metatags';
import { __, __r } from 'react-i18n/lib';
import { SelectDefault } from '../_helpers/forms/Select/Select';
import {
  getProductDefaultInfo,
  resolveCategoryUrl,
} from 'eshop-defaults/lib/utilities/selectors';
import { getConditionTextMapping, getImagePath } from '../../utilities/product';
import { Link, withRouter, WithRouterProps } from 'react-router';
import { useState } from 'react';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import { AddTo } from '../Product/AddTo';
import { AddModal } from '../Product/Product';
import OutsideClick from '../_helpers/Default/OutsideClick';
import {
  addProductToFavorites,
  setAdminContentPath,
  updatePriceDisplaySettings,
} from '../../containers/App/actions';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import {
  setAddToCartModalVisibility,
  setWasAddedToCartModalVisibility,
} from '../../containers/Cart/cartSlice';
import {
  urlAttribsToObject,
  attribsObjectToUrl,
} from '../../containers/Category/categorySlice';
import { push } from 'react-router-redux';
import * as cookie from 'react-cookies';
import { resolveBaseUrlByLang } from '../../configureTrans';
import {
  DesktopWrapper,
  MobileWrapper,
} from '../_helpers/Default/ResponsiveWrappers';
import {
  getAttribNameFromAttribObj,
  getAttribParameterFromAttribObj,
} from '../../utilities/category';
import { resolveUserArePricesLoading } from '../../utilities/user';
import { loadCategoryProducts } from '../../containers/Category/actions';
import { getProductDetailInfo } from 'eshop-defaults';
import { getAvailability } from '../../utilities/microdata';
import { ItemConditions } from '@bart.sk-ecommerce/react-microdata/lib/types';
import { ItemListMicrodata } from '@bart.sk-ecommerce/react-microdata';

export const defaultSortOption = 'available_desc_price_desc';

export const sortOptions: Array<{ name: string; value: string }> = [
  {
    value: 'available_desc_price_desc',
    name: __('Základné radenie'),
  },
  {
    value: 'alphabetically-asc',
    name: __('Názov A-Z'),
  },
  {
    value: 'alphabetically-desc',
    name: __('Názov Z-A'),
  },
  {
    value: 'price-asc',
    name: __('Od najlacnejšieho'),
  },
  {
    value: 'price-desc',
    name: __('Od najdrahšieho'),
  },
];

export interface Props {
  user: any;
  apiUrl: string;
  query: string;
  dispatch: any;
  isFetching: boolean;
  products: ThenArg<typeof API.searchProducts>;
  data: ThenArg<typeof API.loadCategory>;
  subCategories: ThenArg<typeof API.loadSubTree>;
  addToCart: any;
  filterData: {
    minPrice: number;
    maxPrice: number;
    attribs: any;
  };
  currency: string;
  byId: any;
  lang: string;
  categoryAttribs: any;
  hostname: string;
  isSaleout?: boolean;
  domainId: number;
  header?: {
    name?: string;
    description?: string;
    shortDescription?: string;
  };
}

enum FilterType {
  RANGE = 'RANGE',
  OPTIONS = 'CHECKBOX',
  LINKS = 'LINKS',
  CHECK = 'CHECK',
}

function Category({
  user,
  apiUrl,
  isFetching,
  products,
  data,
  subCategories,
  query,
  dispatch,
  addToCart,
  router,
  location,
  filterData,
  currency,
  byId,
  lang,
  categoryAttribs,
  hostname,
  isSaleout,
  domainId,
  header,
}: Props & WithRouterProps) {
  const defaultSortOption = 'available_desc_price_desc';

  const sortOptions: Array<{ name: string; value: string }> = [
    {
      value: 'available_desc_price_desc',
      name: __('Základné radenie'),
    },
    {
      value: 'alphabetically-asc',
      name: __('Názov A-Z'),
    },
    {
      value: 'alphabetically-desc',
      name: __('Názov Z-A'),
    },
    {
      value: 'price-asc',
      name: __('Od najlacnejšieho'),
    },
    {
      value: 'price-desc',
      name: __('Od najdrahšieho'),
    },
  ];

  const isB2B = user && user.b2b;
  const [currentView, setCurrentView] = useLocalStorage(
    'product-view',
    cookie.load(JBL_PRODUCT_VIEW_COOKIE) || 'card',
  );
  const cookieProductView = cookie.load(JBL_PRODUCT_VIEW_COOKIE);

  React.useEffect(() => {
    if (cookieProductView) {
      setCurrentView(cookieProductView);
    }
  }, [cookieProductView]);

  React.useEffect(() => {
    if (data && data.category_id) {
      dispatch(
        setAdminContentPath(
          `/admin/eshop/catalog/category-publish/id/${data.category_id}/`,
        ),
      );
    }

    return () => {
      dispatch(setAdminContentPath(null));
    };
  }, [data]);

  const [vocMoc, setVocMoc] = useState(
    cookie.load(DSI_VOC_VIEW_COOKIE) || 'voc',
  );
  const [vatView, setVatView] = useState(
    cookie.load(DSI_VAT_VIEW_COOKIE) || 'withVat',
  );

  const cookieProductVocView = cookie.load(DSI_VOC_VIEW_COOKIE);
  const cookieProductVatView = cookie.load(DSI_VAT_VIEW_COOKIE);
  React.useEffect(() => {
    if (cookieProductVocView) {
      setVocMoc(cookieProductVocView);
    }
    if (cookieProductVatView) {
      setVatView(cookieProductVatView);
    }
  }, [cookieProductVocView, cookieProductVatView]);

  const [addToFavoriteOpen, setAddToFavoriteOpen] = useState(false);
  const [addToCartOpen, setAddToCartOpen] = useState(false);
  const [addedProduct, setAddedProduct] = useState({
    name: '',
    plu: '',
    picture: '',
    price: '',
    currency: '',
  });
  const [isShowingAllAttribs, setIsShowingAllAttribs] = React.useState(false);

  const catUrl = prop(location, 'pathname');
  const urlAttribs = prop(location, 'query');
  const urlAttribsObj = urlAttribsToObject(urlAttribs);
  const { minPrice, maxPrice, attribs } = filterData;

  const closeModals = () => {
    setAddToFavoriteOpen(false);
    setAddToCartOpen(false);
  };

  const changeAddToFavoriteOpen = () => {
    setAddToFavoriteOpen(!addToFavoriteOpen);
  };

  const addProductToFavorite = id => {
    dispatch(addProductToFavorites(user.id, id));
    setAddToFavoriteOpen(true);
  };

  const changeAddToCartOpen = () => {
    setAddToCartOpen(!addToCartOpen);
  };
  // React.useEffect(() => {
  //   dispatch(setBreadCrumbPath(BreadCrumbType.CATEGORY, data));
  // }, [data, dispatch]);

  React.useEffect(() => {
    /*
    setTimeout(() => {
      const item = window.localStorage.getItem('product-view');
      if (item) {
        setCurrentView(JSON.parse(item));
      }
    }, 2000);
    */

    if (window) {
      const cardElement = window.document.getElementById('cardView');
      if (currentView == 'list' && cardElement) {
        console.log('Wrong layout detected');

        setProductViewCookie('card');
        setCurrentView('card');

        setTimeout(() => {
          setProductViewCookie('list');
          setCurrentView('list');
        }, 200);
      }
    }
  }, []);

  const parameters = prop(urlAttribsObj, 'activeAttribs');
  const activeAttribs = JSON.parse(
    JSON.stringify(prop(urlAttribsObj, 'attribs', {})),
  );
  const activeRangeAttribs = JSON.parse(
    JSON.stringify(prop(urlAttribsObj, 'rangeAttribs', {})),
  );

  // remove not existed attribs
  if (categoryAttribs && activeAttribs) {
    const activeAttribsIds = Object.keys(activeAttribs);
    const categoryAttribsIds = Object.keys(categoryAttribs);
    for (const activeAttribId of activeAttribsIds) {
      if (!categoryAttribsIds.includes(activeAttribId)) {
        console.log('HERE IM');
        delete activeAttribs[activeAttribId];
      }
    }
  }

  const activeCombinedAttribs = { ...activeAttribs, ...activeRangeAttribs };

  if (
    activeCombinedAttribs &&
    categoryAttribs &&
    Object.keys(categoryAttribs).length
  ) {
    Object.keys(activeCombinedAttribs).map(key => {
      const activeValues = activeCombinedAttribs[key].values;
      if (!activeValues) {
        activeCombinedAttribs[key].attribName = getAttribNameFromAttribObj(
          prop(categoryAttribs, `${key}.attrib_data`),
          lang,
        );
        activeCombinedAttribs[key].unit = prop(
          categoryAttribs,
          `${key}.attrib_data.attrib_unit`,
        );
      } else {
        const valuesObj = arrayToObject(
          categoryAttribs[key].values,
          'attrib_value',
        );
        const newValues: any[] = [];
        activeValues.map(value => {
          newValues.push({
            valueId: value,
            name:
              getAttribParameterFromAttribObj(
                prop(valuesObj, `${value}.info`),
                'attrib_value',
                lang,
              ) || prop(valuesObj, `${value}.info.attrib_value`, value),
            attribName: getAttribNameFromAttribObj(
              prop(categoryAttribs, `${key}.attrib_data`),
              lang,
            ),
          });
        });
        activeAttribs[key].values = newValues;
      }
    });
  }

  const attribValuesActive = parameters ? parameters.split(',') : [];
  const activeValues = attribValuesActive
    ? attribValuesActive.map(act => {
        const [, val] = act.split('|');
        return val;
      })
    : [];

  const isMoc = isB2B ? vocMoc === 'moc' : true;
  const withVat = isB2B ? vatView === 'withVat' : true;
  const minP = Math.floor(
    isMoc
      ? withVat
        ? +prop(products, 'price_min', 0)
        : +prop(products, 'price_min_without_vat', 0)
      : withVat
      ? +prop(products, 'voc_price_min', 0)
      : +prop(products, 'voc_price_min_without_vat', 0),
  );
  const maxP = Math.ceil(
    isMoc
      ? withVat
        ? prop(products, 'price_max', 0)
        : prop(products, 'price_max_without_vat', 0)
      : withVat
      ? prop(products, 'voc_price_max', 0)
      : prop(products, 'voc_price_max_without_vat', 0),
  );
  const currentMin = prop(location, 'query.min', minP);
  const currentMax = prop(location, 'query.max', maxP);
  const isCardView = currentView !== 'list';

  const handleCheckboxChange = ({ name, value }) => {
    router.push(
      attribsObjectToUrl(catUrl, {
        ...urlAttribsObj,
        otherAttribs: {
          ...urlAttribsObj.otherAttribs,
          [name]: value ? 1 : 0,
        },
      }),
    );
  };

  const toggleShowingAllParameters = () => {
    setIsShowingAllAttribs(isShowingAllAttribs => !isShowingAllAttribs);
  };

  const renderCustomFilters = (isMobile = false) => {
    if (!categoryAttribs) {
      return null;
    }

    let keys = Object.keys(categoryAttribs);
    const activeKeys = keys.filter(k =>
      prop(categoryAttribs, `${k}.hasActive`),
    );
    const otherKeys = keys.filter(
      k => !prop(categoryAttribs, `${k}.hasActive`),
    );
    otherKeys.sort(function(a, b) {
      const aSort = prop(categoryAttribs[a], 'attrib_data.attrib_sort');
      const bSort = prop(categoryAttribs[b], 'attrib_data.attrib_sort');

      //   const isRange = prop(attribData, 'attrib_type') === 'rozsah';
      // const hasActive = isRange
      //     ? activeRangeAttribs[prop(attribData, 'attrib_id')]
      //     : values.find(v => activeValues.includes(v.attrib_value));
      return (
        prop(categoryAttribs[a], 'attrib_data.attrib_sort') -
        prop(categoryAttribs[b], 'attrib_data.attrib_sort')
      );
    });

    keys = [...activeKeys, ...otherKeys];
    keys = keys.filter(key => {
      const attrib = categoryAttribs[key];
      const attribData = prop(attrib, 'attrib_data');
      const isRange = prop(attribData, 'attrib_type') === 'rozsah';
      const isBrand = prop(attribData, 'attrib_uid') === 'ZNACKA';
      const values = attrib.values || [];
      const valuesWithDocCount = values.filter(v => v.doc_count > 0);
      const hasActive = isRange
        ? activeRangeAttribs[prop(attribData, 'attrib_id')]
        : values.find(v => activeValues.includes(v.attrib_value));
      const hasMoreThanOneValue = isRange
        ? true // show always with range
        : isBrand
        ? valuesWithDocCount.length > 0
        : valuesWithDocCount.length > 1;
      if (!hasMoreThanOneValue && !hasActive) {
        return null;
      }

      return key;
    });

    const keysAltered = isShowingAllAttribs
      ? keys
      : keys.slice(0, 5 + activeKeys.length);

    return (
      <>
        {categoryAttribs &&
          keysAltered.map(key => {
            const attrib = categoryAttribs[key];
            const attribData = prop(attrib, 'attrib_data');
            const isRange = prop(attribData, 'attrib_type') === 'rozsah';
            const values = attrib.values || [];
            const hasActive = isRange
              ? activeRangeAttribs[prop(attribData, 'attrib_id')]
              : values.find(v => activeValues.includes(v.attrib_value));
            const isBrand = prop(attribData, 'attrib_uid') === 'ZNACKA';

            return (
              <CategoryFilter
                type={isRange ? FilterType.RANGE : FilterType.OPTIONS}
                name={getAttribNameFromAttribObj(
                  prop(categoryAttribs, `${key}.attrib_data`),
                  lang,
                )}
                initialIsOpen={hasActive || isBrand ? true : false}
                filterData={
                  isRange
                    ? {
                        min: attrib.min,
                        max: attrib.max,
                        activeMax:
                          prop(
                            activeRangeAttribs[prop(attribData, 'attrib_id')],
                            'max',
                          ) || attrib.max,
                        activeMin:
                          prop(
                            activeRangeAttribs[prop(attribData, 'attrib_id')],
                            'min',
                          ) || attrib.min,
                        attribId: prop(attribData, 'attrib_id'),
                      }
                    : {
                        attribId: prop(attribData, 'attrib_id'),
                        values: values.map(v => ({
                          ...v,
                          info: {
                            ...v.info,
                            attrib_value: getAttribParameterFromAttribObj(
                              v.info,
                              'attrib_value',
                              lang,
                            ),
                            attrib_link: getAttribParameterFromAttribObj(
                              v.info,
                              'attrib_link',
                              lang,
                            ),
                          },
                        })),
                      }
                }
                handleFilterChange={handleFilterChange}
                isMobile={isMobile}
                unit={prop(attribData, 'attrib_unit')}
                activeValues={activeValues}
              />
            );
          })}
        {keysAltered && keys.length > keysAltered.length ? (
          <ShowAllAttribs onClick={toggleShowingAllParameters}>
            {isShowingAllAttribs
              ? __('Skryť ďalšie parametre')
              : __('Zobraziť ďalšie parametre')}
          </ShowAllAttribs>
        ) : null}
      </>
    );
  };

  const renderLeftSide = () => {
    if (isFetching) {
      return null;
    }

    return (
      <LeftSideWrapper>
        <ProductFilter
          handleFilterChange={handleFilterChange}
          isMobile={false}
          priceInitial={{
            min: minP,
            max: maxP,
          }}
          priceCurrent={{ min: +currentMin, max: +currentMax }}
          currency={currency}
          data={urlAttribsObj}
          showVocMoc={isB2B}
          showVat={isB2B}
          handleCheckboxChange={handleVatVocCheckboxChange}
          vocMoc={vocMoc}
          vatView={vatView}
          hasOnStockFilter={true}
          aggData={products}
        />
        {renderCustomFilters()}
        <ResetFilter url={catUrl} name={__('Vymazať filter')} />
      </LeftSideWrapper>
    );
  };

  const renderRightSide = () => {
    const offset = prop(products, 'offset', 0);
    const limit = prop(products, 'limit', 0);
    const total = prop(products, 'total', 0);
    const currentSortValue = prop(location, 'query.sort', '');
    const currentSortDirValue = prop(location, 'query.sortDir', '');

    const categoryName = isSaleout
      ? __('Výpredaj')
      : header?.name
      ? header.name
      : prop(
          data,
          'publish.0.category_name',
          prop(data, 'content.0.name', prop(data, 'category_name', '')),
        ) || prop(data, 'category_name', '');

    const categoryDesc = header?.description
      ? header.description
      : prop(
          data,
          'publish.0.content.json_content.body',
          prop(data, 'content.0.json_content.body', null),
        );

    const onSelectChange = e => {
      const value = e.target.value;
      const [sort, sortDir] = value.split('-');
      router.push(
        attribsObjectToUrl(catUrl, {
          ...urlAttribsObj,
          sort,
          sortDir: sortDir ? sortDir : null,
        }),
      );
    };

    const categoryShortDesc = header?.shortDescription
      ? header.shortDescription
      : prop(
          data,
          'publish.0.content.json_content.short_body',
          prop(data, 'content.0.json_content.short_body', null),
        );

    return (
      <RightSideWrapper>
        <CategoryHeader
          title={categoryName}
          desc={categoryDesc}
          short_desc={categoryShortDesc}
        />
        {isFetching ? (
          <ZemplinLoaderWrapper height={500} />
        ) : !products || !products.products ? null : (
          <>
            <SubCategoriesWrapper>
              {subCategories &&
                Array.isArray(subCategories) &&
                subCategories.map((item: any) => (
                  <SubCategory
                    to={
                      item.weburl
                        ? resolveCategoryUrl(
                            item.redirect_category_id,
                            prop(
                              byId,
                              `${item.redirect_category_id}.publish.0.url`,
                            ) || item.weburl,
                            'CATEGORYTEXT/URL',
                          )
                        : resolveCategoryUrl(
                            item.category_id,
                            prop(
                              byId,
                              `${item.redirect_category_id}.publish.0.url`,
                            ) ||
                              prop(item, 'publish.0.url') ||
                              item.url ||
                              'url',
                            'CATEGORYTEXT/URL',
                          )
                    }
                    draggable={false}
                  >
                    <SubImage
                      src={
                        item.image
                          ? getImagePath(
                              item.image,
                              { width: 40, height: 0 },
                              false,
                            )
                          : '/images/icons/placeholder.svg'
                      }
                      alt={`${__('Obrázok kategórie')} ${prop(
                        item,
                        'publish.0.content.json_content.shortname',
                      ) ||
                        prop(item, 'publish.0.category_name') ||
                        item.category_name}`}
                    />
                    <SubCatNameWrapper>
                      <SubName>
                        {prop(
                          item,
                          'publish.0.content.json_content.shortname',
                        ) ||
                          prop(item, 'publish.0.category_name') ||
                          item.category_name}
                      </SubName>
                    </SubCatNameWrapper>
                  </SubCategory>
                ))}
            </SubCategoriesWrapper>
            <FiltersMobileWrapper>
              <ActiveFilters
                resetUrl={catUrl}
                activeAttribs={activeCombinedAttribs}
                onClickAttrib={handleFilterChange}
              />
              <FoundProducts>
                {`${__('Nájdených produktov')}: `} <b>{total}</b>
              </FoundProducts>
            </FiltersMobileWrapper>
            <SortWrapper>
              <SortSelectInfoWrapper>
                <SortSelect
                  name="sort"
                  id="sort"
                  options={sortOptions}
                  // placeholder={__('Najpredávanejšie')}
                  onChange={onSelectChange}
                  defaultValue={
                    currentSortValue
                      ? `${currentSortValue}-${currentSortDirValue}`
                      : defaultSortOption
                  }
                />
                <InfoIconLink
                  href={__r('/informacie-o-radeni', '/informacie-o-radeni')}
                >
                  <InfoIcon src="/images/icons/outline-info.svg" />
                </InfoIconLink>
              </SortSelectInfoWrapper>
              <ViewSwitcher
                changeView={changeView}
                currentView={currentView}
                isDsi={true}
              />
            </SortWrapper>
            <FiltersDesktopWrapper>
              <ActiveFilters
                resetUrl={catUrl}
                activeAttribs={activeCombinedAttribs}
                onClickAttrib={handleFilterChange}
              />
              <FoundProducts>
                {`${__('Nájdených produktov')}: `} <b>{total}</b>
              </FoundProducts>
            </FiltersDesktopWrapper>
            {renderProductsCardView()}
            {renderProductsListView()}
            <PaginationDesktopWrapper>
              <Pagination
                query={`${location.pathname}${
                  location.search ? location.search : '?'
                }`}
                totalItems={total || 0}
                limit={limit || 0}
                offset={offset || 0}
                isMobile={false}
              />
            </PaginationDesktopWrapper>
            <PaginationMobileWrapper>
              <Pagination
                query={`${location.pathname}${
                  location.search ? location.search : '?'
                }`}
                totalItems={total || 0}
                limit={limit || 0}
                offset={offset || 0}
                isMobile={true}
              />
            </PaginationMobileWrapper>
          </>
        )}
      </RightSideWrapper>
    );
  };

  const PaginationDesktopWrapper = styled.div`
    display: block;

    ${({ theme }) => theme.mediab.m580`
      display: none;
    `}
  `;

  const PaginationMobileWrapper = styled.div`
    display: none;

    ${({ theme }) => theme.mediab.m580`
      display: block;
    `}
  `;

  const renderProductsCardView = () => {
    if (!isCardView) {
      return null;
    }

    return (
      <div id="cardView">
        <ProductList
          apiUrl={apiUrl}
          isFetching={isFetching}
          products={products.products}
          addToCart={handleAddToCart}
          addToRequest={addToRequest}
          visible={isCardView}
          addToFavorite={addProductToFavorite}
          hasFavourite={false}
          canOrder={true}
          showMoc={isB2B ? vocMoc === 'moc' : true}
          showWithVat={isB2B ? vatView === 'withVat' : true}
          loadingPrices={resolveUserArePricesLoading(user)}
          isB2b={user && user.b2b}
          showStockCount={!!(user && user.b2b && user.originalUser)}
          domainId={domainId}
          conditionTextMapping={getConditionTextMapping()}
          user={user}
        />
      </div>
    );
  };

  const renderProductsListView = () => {
    if (isCardView) {
      return null;
    }
    return (
      <div id="listView">
        <ProductTableList
          apiUrl={apiUrl}
          isFetching={isFetching}
          products={products.products}
          addToCart={handleAddToCart}
          addToRequest={addToRequest}
          visible={!isCardView}
          showSumOfVariants={true}
          hasFavourite={false}
          hasWatchdog={false}
          canOrder={true}
          showMoc={isB2B ? vocMoc === 'moc' : true}
          isB2b={user && user.b2b}
          showWithVat={isB2B ? vatView === 'withVat' : true}
          loadingPrices={resolveUserArePricesLoading(user)}
          showStockCount={!!(user && user.b2b && user.originalUser)}
          domainId={domainId}
          user={user}
          conditionTextMapping={getConditionTextMapping()}
        />
      </div>
    );
  };

  const renderMicrodata = () => {
    const catUrl = prop(location, 'pathname');
    if (products && products.products && products.products.length) {
      const productsMicrodata: any = products.products
        .map(product => {
          const info = getProductDetailInfo(
            (product as any).product_detail,
            apiUrl,
          );

          const firstPublish = info.publish[0];

          if (!firstPublish || !firstPublish.content) {
            return null;
          }

          const calculatedImages = prop(
            firstPublish,
            'content.calculated_meta.images',
            [],
          );
          const calculatedVideos = prop(
            firstPublish,
            'content.calculated_meta.videos',
            [],
          );
          const calculatedYoutubeVideos = prop(
            firstPublish,
            'content.calculated_meta.youtubeVideos',
            [],
          );
          let short_description = '';
          if (firstPublish) {
            short_description =
              firstPublish.content.json_content.short_description;
          }

          return {
            name: info.name,
            brand: info.brand,
            sku: info.plu,
            description: short_description,
            gtin: product ? (product as any).product_ean : null,
            weight:
              product && (product as any).product_weight
                ? (product as any).product_weight + 'kg'
                : null,
            width:
              product &&
              (product as any).main_good &&
              ((product as any).main_good as any).width
                ? ((product as any).main_good as any).width + 'cm'
                : undefined,
            height:
              product &&
              (product as any).main_good &&
              ((product as any).main_good as any).height
                ? ((product as any).main_good as any).height + 'cm'
                : undefined,
            depth:
              product &&
              (product as any).main_good &&
              ((product as any).main_good as any).length
                ? ((product as any).main_good as any).length + 'cm'
                : null,
            mpn: info.plu,
            offers:
              info.price && info.price > 0
                ? {
                    availability:
                      getAvailability(
                        info.availability,
                        info.onStockCount,
                        info.isNew,
                        false,
                        info.isWaiting,
                      ) || 'OutOfStock',
                    price: info.price,
                    priceCurrency: currency,
                    itemCondition: info.condition
                      ? ItemConditions.UsedCondition
                      : ItemConditions.NewCondition,
                  }
                : undefined,
            videoObject: calculatedVideos
              .map(item => {
                return { contentUrl: item.url };
              })
              .concat(
                calculatedYoutubeVideos.map(item => {
                  return {
                    contentUrl: item.url,
                    thumbnailUrl: item.thumbnailUrl,
                    description: item.description
                      ? item.description
                      : item.name,
                    uploadDate: item.uploadDate,
                    name: item.name,
                  };
                }),
              ),
            imageObject: calculatedImages
              .map(item => {
                return {
                  url: item.url,
                  width: item.width,
                  height: item.height,
                };
              })
              .concat(
                info.image
                  ? [
                      {
                        url: getImagePath('product/' + info.image, {
                          width: 160,
                          height: 160,
                        }),
                      },
                    ]
                  : [],
              ),
          };
        })
        .filter(o => o);

      return <ItemListMicrodata url={catUrl} products={productsMicrodata} />;
    } else {
      return <></>;
    }
  };

  const renderMobileFilter = () => {
    const [isActiveCategory, setIsActiveCategory] = useState(true);
    const [isOpenCategory, setIsOpenCategory] = useState(false);
    const [isActiveFilter, setIsActiveFilter] = useState(true);
    const [isOpenFilter, setIsOpenFilter] = useState(false);

    const resetStateCategory = () => {
      if (isOpenCategory) {
        setIsOpenCategory(!isOpenCategory);
        setIsActiveFilter(!isActiveFilter);
      } else {
        if (isOpenFilter) {
          setIsOpenFilter(!isOpenFilter);
          setIsActiveFilter(!isActiveFilter);
          setIsActiveCategory(!isActiveCategory);
          setIsOpenCategory(!isOpenCategory);
        } else {
          setIsOpenCategory(!isOpenCategory);
          setIsActiveFilter(!isActiveFilter);
        }
      }
    };

    const resetStateFilter = () => {
      if (isOpenFilter) {
        setIsOpenFilter(!isOpenFilter);
        setIsActiveCategory(!isActiveCategory);
      } else {
        if (isOpenCategory) {
          setIsOpenCategory(!isOpenCategory);
          setIsActiveCategory(!isActiveCategory);
          setIsActiveFilter(!isActiveFilter);
          setIsOpenFilter(!isOpenFilter);
        } else {
          setIsOpenFilter(!isOpenFilter);
          setIsActiveCategory(!isActiveCategory);
        }
      }
    };

    const hasSubCategories =
      subCategories && Array.isArray(subCategories) && subCategories.length > 0;
    return (
      <>
        <MobileHeaderFilterWrapper>
          {hasSubCategories && (
            <MobileFilterHeaderItem
              isOpen={isOpenCategory}
              isActive={isActiveCategory}
              onClick={resetStateCategory}
            >
              <FilterHeaderItemWrapper>
                <FilterHeaderItemName>
                  <DsiSvgIcon
                    icon={DsiIconType.category}
                    viewBox="0 0 24 24"
                    alt="Filter"
                    cursor="pointer"
                    marginRight={8}
                  />
                  {__('Podkategória')}
                </FilterHeaderItemName>
              </FilterHeaderItemWrapper>
            </MobileFilterHeaderItem>
          )}
          <MobileFilterHeaderItem
            isOpen={isOpenFilter}
            isActive={isActiveFilter}
            onClick={resetStateFilter}
            fullWidth={!hasSubCategories}
          >
            <FilterHeaderItemWrapper>
              <FilterHeaderItemName>
                <DsiSvgIcon
                  icon={DsiIconType.filter}
                  viewBox="0 0 24 24"
                  alt="Filter"
                  cursor="pointer"
                  marginRight={8}
                />
                {__('Filter')}
              </FilterHeaderItemName>
            </FilterHeaderItemWrapper>
          </MobileFilterHeaderItem>
        </MobileHeaderFilterWrapper>
        <MobileFilterWrapper isOpen={isOpenFilter}>
          <ProductFilter
            handleFilterChange={handleFilterChange}
            isMobile={true}
            priceInitial={{
              min: minP,
              max: maxP,
            }}
            priceCurrent={{ min: +currentMin, max: +currentMax }}
            currency={currency}
            data={urlAttribsObj}
            showVocMoc={isB2B}
            showVat={isB2B}
            handleCheckboxChange={handleVatVocCheckboxChange}
            vocMoc={vocMoc}
            vatView={vatView}
            hasOnStockFilter={true}
            aggData={products}
          />
          {renderCustomFilters(true)}
        </MobileFilterWrapper>
        <MobileSubCategoryWrapper isOpen={isOpenCategory}>
          {subCategories &&
            Array.isArray(subCategories) &&
            subCategories.map((item: any) => (
              <SubCategory
                to={
                  item.weburl
                    ? resolveCategoryUrl(
                        item.redirect_category_id,
                        prop(
                          byId,
                          `${item.redirect_category_id}.publish.0.url`,
                        ) || item.weburl,
                        'CATEGORYTEXT/URL',
                      )
                    : resolveCategoryUrl(
                        item.category_id,
                        prop(
                          byId,
                          `${item.redirect_category_id}.publish.0.url`,
                        ) ||
                          prop(item, 'publish.0.url') ||
                          item.url,
                        'CATEGORYTEXT/URL',
                      )
                }
              >
                <SubImage
                  src={
                    item.image
                      ? getImagePath(item.image, { width: 164, height: 164 })
                      : '/images/icons/placeholder.svg'
                  }
                  alt={`${__('Obrázok kategórie')} ${prop(
                    item,
                    'publish.0.content.json_content.shortname',
                  ) ||
                    prop(item, 'publish.0.category_name') ||
                    item.category_name}`}
                />
                <SubCatNameWrapper>
                  <SubName>
                    {prop(item, 'publish.0.content.json_content.shortname') ||
                      prop(item, 'publish.0.category_name') ||
                      item.category_name}
                  </SubName>
                </SubCatNameWrapper>
              </SubCategory>
            ))}
        </MobileSubCategoryWrapper>
      </>
    );
  };

  const renderAddToFavoritesModal = () => {
    return (
      addToFavoriteOpen && (
        <>
          <Dimmer height={100} zIndex={99} />
          <StyledOutsideClick handleFunction={closeModals}>
            <AddModal>
              <AddTo
                type={'favorite'}
                addToFavoriteOpen={changeAddToFavoriteOpen}
                productName={name}
                picture={''}
              />
            </AddModal>
          </StyledOutsideClick>
        </>
      )
    );
  };

  const renderAddToCartModal = () => {
    return (
      addToCartOpen && (
        <>
          <Dimmer height={100} zIndex={99} />
          <StyledOutsideClick handleFunction={closeModals}>
            <AddModal>
              <AddTo
                type={'cart'}
                addToFavoriteOpen={changeAddToCartOpen}
                productName={
                  prop(addedProduct, 'publish.0.product_name') ||
                  addedProduct.name
                }
                picture={addedProduct.picture}
                price={addedProduct.price}
                currency={addedProduct.currency}
              />
            </AddModal>
          </StyledOutsideClick>
        </>
      )
    );
  };

  const changeView = () => {
    const newView = currentView === 'card' ? 'list' : 'card';
    setProductViewCookie(newView);
    setCurrentView(newView);
  };

  // const handleOffsetChange = async (newOffset: number) => {
  //   dispatch(
  //     push({
  //       pathname: location.pathname,
  //       search: `?offset=${newOffset}`,
  //     }),
  //   );
  // };

  const handleVatVocCheckboxChange = (name, value) => {
    if (name === 'vat') {
      setVatView(value);
      setVatViewCookie(value);
      dispatch(
        updatePriceDisplaySettings(
          value === 'withVat' ? 1 : 2,
          vocMoc === 'voc' ? 1 : 2,
        ),
      );
    } else if (name === 'vocmoc') {
      setVocMoc(value);
      setVocViewCookie(value);
      dispatch(
        updatePriceDisplaySettings(
          vatView === 'withVat' ? 1 : 2,
          value === 'voc' ? 1 : 2,
        ),
      );
    }

    dispatch(
      loadCategoryProducts(
        data.category_id,
        urlAttribsToObject(location.query),
      ),
    );
  };

  const handleFilterChange = (
    type: FilterType,
    newData:
      | { min: number; max: number; attribId?: string }
      | {
          attribId: string;
          valueId: string;
          newValue: boolean;
          sameIds?: string[];
        }
      | any,
  ) => {
    // console.log('filter change');
    switch (type) {
      case FilterType.RANGE: {
        const attribId = prop(newData, 'attribId');
        if (attribId) {
          const currentRangeAttribFilters = prop(urlAttribsObj, 'rangeAttribs');
          const min = parseInt(prop(newData, 'min').toFixed(0));
          const max = parseInt(prop(newData, 'max').toFixed(0));
          const { attribs } = filterData;
          const attribDataMin = prop(categoryAttribs, `${attribId}.min`);
          const attribDataMax = prop(categoryAttribs, `${attribId}.max`);

          let rangeAttribsObj = currentRangeAttribFilters
            ? currentRangeAttribFilters
            : {};

          if (
            Math.floor(attribDataMin) !== min ||
            Math.ceil(attribDataMax) !== max
          ) {
            rangeAttribsObj[attribId] = {
              min: parseInt(prop(newData, 'min').toFixed(0)),
              max: parseInt(prop(newData, 'max').toFixed(0)),
            };
          } else {
            delete rangeAttribsObj[attribId];
          }
          router.push(
            attribsObjectToUrl(catUrl, {
              ...urlAttribsObj,
              rangeAttribs: rangeAttribsObj,
            }),
          );
        } else {
          urlAttribsObj.min = parseInt(prop(newData, 'min').toFixed(0));
          urlAttribsObj.max = parseInt(prop(newData, 'max').toFixed(0));
          router.push(attribsObjectToUrl(catUrl, urlAttribsObj));
        }

        break;
      }

      case FilterType.OPTIONS: {
        const attribId = prop(newData, 'attribId');
        const valueId = prop(newData, 'valueId');
        const newValue = prop(newData, 'newValue');
        const currentRangeAttribFilters = prop(urlAttribsObj, 'rangeAttribs');
        const currentAttribFilters = prop(urlAttribsObj, 'attribs');
        let updatedAttribsObj = currentAttribFilters;
        let values = updatedAttribsObj
          ? prop(currentAttribFilters[attribId], 'values')
          : null;

        if (values) {
          if (newValue) {
            values.push(valueId);
          } else {
            values = values.filter(v => valueId.toString() !== v);
          }
          if (values.length === 0) {
            updatedAttribsObj[attribId].values = null;
            delete updatedAttribsObj[attribId];
          } else {
            updatedAttribsObj[attribId].values = values;
          }
        } else if (!valueId) {
          delete currentRangeAttribFilters[attribId];
        } else {
          updatedAttribsObj = {
            ...updatedAttribsObj,
            [attribId]: { values: [valueId] },
          };
        }
        router.push(
          attribsObjectToUrl(catUrl, {
            ...urlAttribsObj,
            attribs: updatedAttribsObj,
            rangeAttribs: currentRangeAttribFilters,
          }),
        );

        break;
      }

      case FilterType.CHECK: {
        handleCheckboxChange({
          name: newData.target.name,
          value: newData.target.value,
        });
        break;
      }

      default:
        break;
    }
  };

  const handleAddToCart = (product: any, count: number) => {
    const { name, plu, price, currency, picture } = getProductDefaultInfo(
      product,
    );
    setAddedProduct({
      name: name,
      plu: plu,
      price: price,
      currency: currency,
      picture: picture,
    });
    setAddToCartOpen(true);
    addToCart(product, count);
  };

  const addToRequest = () => {
    // console.log('add to request');
  };

  let langVersions = null;
  if ((data as any)?.lang_versions) {
    langVersions = (data as any)?.lang_versions.map(langVersion => {
      return {
        lang_id: langVersion.lang_id,
        url: prefixUrl(
          langVersion.lang_id === 'cz' ? 'kategorie' : 'kategoria',
          langVersion.url,
        ),
      };
    });
  }

  return (
    <>
      {renderMobileFilter()}
      {/* {renderAddToCartModal()}
      {renderAddToFavoritesModal()} */}
      <Wrapper className="container container--wide">
        {renderMicrodata()}
        <MetaTags
          tags={getCategoryMetaTags(data)}
          canonicalUrl={`${resolveBaseUrlByLang(lang)}${location.pathname}`}
          hrefLangs={langVersions}
        />
        {renderLeftSide()}
        {renderRightSide()}
      </Wrapper>
    </>
  );
}

const Wrapper = styled(FlexRow)`
  width: 100%;
  margin-bottom: ${rem(80)} !important;

  ${({ theme }) => theme.mediab.m810`
      padding-top: ${rem(32)};
      margin-bottom: ${rem(32)} !important;
  `}
`;

export const StyledOutsideClick = styled(OutsideClick)`
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
  z-index: 0;
`;

export const MobileHeaderFilterWrapper = styled.div`
  width: 100%;
  display: none;
  ${({ theme }) => theme.mediab.m810`
      display: flex;
      flex-direction: row;
  `}
`;

const SubCategoriesWrapper = styled.div`
  /* width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; */

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${rem(16)};
  grid-auto-rows: minmax(64, auto);
  margin-bottom: ${rem(16)};

  ${({ theme }) => theme.mediab.l1150`
    grid-template-columns: repeat(3, 1fr);
  `};

  @media (max-width: ${rem(991)}) {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.mediab.m810`
      display: none;
  `}
`;

export const MobileFilterWrapper = styled.div<{ isOpen: boolean }>`
  display: none;
  ${({ theme }) => theme.mediab.m810`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  `}
`;

export const MobileSubCategoryWrapper = styled(SubCategoriesWrapper)<{
  isOpen: boolean;
}>`
  display: none;
  ${({ theme }) => theme.mediab.m810`
      display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  `}
`;

export const MobileFilterHeaderItem = styled(FlexRowCenterVertical)<{
  isOpen: boolean;
  isActive: boolean;
  fullWidth?: boolean;
}>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
  padding: ${rem(16)} 0;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.white : theme.colors.categoryFilterBackground};
  border-bottom: ${({ isOpen, theme }) => (isOpen ? 0 : theme.borders.primary)};
  cursor: pointer;

  &:last-child {
    border-left: ${({ theme }) => theme.borders.primary};
  }
`;

export const FilterHeaderItemWrapper = styled(FlexRowCenterVertical)`
  margin: 0 auto;
`;

export const FilterHeaderItemName = styled(TextP)`
  font-size: ${rem(16)};
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.colors.textPrimary};

    * {
      fill: inherit;
    }
  }
`;

export const RightSideWrapper = styled(FlexCol)`
  /* padding: ${rem(24)}; */
  
  width: 100%;
`;

export const LeftSideWrapper = styled(FlexCol)`
  max-width: ${rem(305)};
  margin-right: ${rem(35)};
  width: 100%;

  ${({ theme }) => theme.mediab.m810`
      display: none;
  `}
`;

export const InfoIconLink = styled.a``;

export const InfoIcon = styled.img`
  height: ${rem(24)};
  margin-left: ${rem(12)};
  filter: invert(1);
`;

export const SortWrapper = styled(FlexRowCenterVertical)`
  margin-top: ${rem(16)};
  margin-bottom: ${rem(16)};
  justify-content: space-between;

  ${({ theme }) => theme.mediab.m810`
      margin-bottom: ${rem(32)};
      margin-top: 0;
  `}
`;

export const SortSelectInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SortSelect = styled(SelectDefault)`
  width: ${rem(320)};

  ${({ theme }) => theme.mediab.l925`
      width: ${rem(200)};
  `}
`;

export const SubCategory = styled(Link)`
  text-decoration: none;
  width: 100%;
  /* max-width: ${rem(255)}; */
  padding: ${rem(13)} ${rem(16)};
  /* margin: 0 ${rem(16)} ${rem(16)} 0; */
  background: ${({ theme }) => rgba(theme.colors.textPrimary, 0.04)};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.textPrimary};

  ${({ theme }) => theme.mediab.l925`
     background: ${({ theme }) => theme.colors.white};
     border-bottom: ${rem(1)} solid #eeeeee;
  `}
`;

export const SubImage = styled(IconImg)`
  min-width: ${rem(40)};
  height: auto;
  max-height: ${rem(40)};
  margin-right: ${rem(16)};
  cursor: pointer;

  ${({ theme }) => theme.mediab.l925`
     margin: 0 auto;
     min-width: initial;
     height: ${rem(24)};
  `}
`;

export const SubCatNameWrapper = styled.div`
  height: 40px;
  display: table;
  overflow: hidden;
  cursor: pointer;

  ${({ theme }) => theme.mediab.l925`
     width: 100%;
     display: block;
     margin-left: ${rem(16)};
     height: initial;
  `}
`;

export const SubName = styled(TextP)`
  font-size: ${rem(16)};
  display: table-cell;
  vertical-align: middle;

  ${({ theme }) => theme.mediab.l925`
     text-align: left;
     display: block;
     text-transform: none;
     font-size: ${rem(14)};
     line-height: ${rem(18)};
     color: ${({ theme }) => theme.colors.textPrimary};
  `}

  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-user-select: text;
  -moz-select: text;
  -ms-select: text;
  user-select: text;
`;

const MobileSubCategoryTitle = styled(TextP)`
  font-weight: 500;
  font-size: ${rem(18)};
  line-height: ${rem(22)};
  background: ${({ theme }) => theme.colors.white};
  margin: 0;
  padding: ${rem(24)} ${rem(16)};
  border-bottom: ${rem(1)} solid #eeeeee;
`;

const FiltersDesktopWrapper = styled.div`
  display: block;
  /* margin-bottom: ${rem(16)}; */

  ${({ theme }) => theme.mediab.m810`
     display: none;
  `}
`;

const FiltersMobileWrapper = styled.div`
  display: none;
  margin-bottom: ${rem(16)};

  ${({ theme }) => theme.mediab.m810`
     display: block;
  `}
`;

const FoundProducts = styled.div`
  margin-top: ${rem(16)};
`;

const ShowAllAttribs = styled.div`
  background: ${({ theme, isMobile }) =>
    isMobile ? theme.colors.white : theme.categoryFilter.bgColor};
  padding: ${({ theme }) => theme.categoryFilter.padding};
  margin: ${({ theme }) => theme.categoryFilter.margin};
  border: ${({ theme }) => theme.categoryFilter.border};
  border-radius: ${({ theme }) => theme.categoryFilter.borderRadius};
  color: ${({ theme }) => theme.colors.textPrimary};
  padding: ${rem(16)} ${rem(32)};
    /* font-weight: 500;
    line-height: ${rem(32)};
    font-size: ${rem(20)}; */
  cursor: pointer;
`;

export default withRouter(Category);
