import {
  CategoriesTree,
  CatgoriesActionsTypes,
  SearchTermType,
  SubcategoriesTree,
  SubcatriesActionsTypes,
} from './types';
import { combineReducers } from 'redux';

const mapCategoriesTreeById = (data: CategoriesTree | SubcategoriesTree) => {
  return data.reduce((acc, category) => {
    return {
      ...acc,
      [category.category_id]: {
        ...category,
      },
    };
  }, {});
};

const mapCategoriesTreeByIdRecursive = (
  data: CategoriesTree | SubcategoriesTree,
) => {
  return data.reduce((acc, category) => {
    return {
      ...acc,
      [category.category_id]: {
        ...category,
      },
      ...mapCategoriesTreeByIdRecursive(category.children as SubcategoriesTree),
    };
  }, {});
};

const categoriesReducer = (
  state: any = {},
  action: CatgoriesActionsTypes | SubcatriesActionsTypes | any,
) => {
  switch (action.type) {
    case 'Header/CHANGE_TOGGLE_VISIBLE': {
      return {
        ...state,
        dimmerVisible: action.payload.newValue,
      };
    }

    case 'Header/REQUEST_CATEGORIES': {
      return {
        ...state,
        isFetching: true,
      };
    }

    case 'Header/RECEIVE_CATEGORIES_SUCCESS': {
      return {
        ...state,
        isFetching: false,
        productCategories: action.payload.categories,
        byId: mapCategoriesTreeByIdRecursive(action.payload.categories),
        // mainCategories: action.payload.categories.map(
        //   category => category.category_id,
        // ),
      };
    }

    case 'Header/RECEIVE_CATEGORIES_ERROR': {
      return {
        ...state,
        isFetching: false,
        byId: {},
        allIds: [],
        error: action.payload.error,
      };
    }

    case 'Header/REQUEST_SUBCATEGORIES': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.parentCategoryId]: {
            ...state.byId[action.payload.parentCategoryId],
            subcategoriesId: [],
            isFetchingSubcategories: true,
          },
        },
      };
    }

    case 'Header/RECEIVE_SUBCATEGORIES_SUCCESS': {
      const subcategoriesId: number[] = action.payload.subcatgories.map(
        subcategory => subcategory.category_id,
      );
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.parentCategoryId]: {
            ...state.byId[action.payload.parentCategoryId],
            subcategoriesId,
            isFetchingSubcategories: false,
          },
          ...mapCategoriesTreeById(action.payload.subcatgories),
        },
      };
    }

    default: {
      return state;
    }
  }
};

const landingPagesReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case 'Header/REQUEST_LANDING_PAGES': {
      return {
        ...state,
        isFetching: true,
      };
    }

    case 'Header/RECEIVE_LANDING_PAGES_SUCCESS': {
      return {
        ...state,
        isFetching: false,
        landingPages: action.payload.landingPages,
      };
    }

    case 'Header/RECEIVE_CATEGORIES_ERROR': {
      return {
        ...state,
        isFetching: false,
        landingPages: [],
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

const searchFormReducer = (
  state = {
    searchTerm: '',
  },
  action: SearchTermType,
) => {
  switch (action.type) {
    case 'Header/REQUEST_SEARCH_TERM': {
      return {
        searchTerm: action.payload.searchTerm,
        isFetching: true,
      };
    }

    case 'Header/RECEIVE_SEARCH_TERM_SUCCESS': {
      if (action.payload.searchTerm !== state.searchTerm) {
        return state;
      }
      return {
        ...state,
        isFetching: false,
        results: {
          productsResult: action.payload.articles,
        },
      };
    }

    case 'Header/RECEIVE_SEARCH_TERM_ERROR': {
      return {
        isFetching: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export const headerReducer = combineReducers({
  categories: categoriesReducer,
  searchForm: searchFormReducer,
  landingPages: landingPagesReducer,
});
