const namespace = 'App';

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_LANGUAGE_CHANGED = 'SET_LANGUAGE_CHANGED';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_DEFAULT_TITLE = 'SET_DEFAULT_TITLE';
export const SET_DEFAULT_HOME_TITLE = 'SET_DEFAULT_HOME_TITLE';
export const SET_HEADER_DATA = 'SET_HEADER_DATA';
export const SET_FOOTER_DATA = 'SET_FOOTER_DATA';
export const SET_CONTACT_INFO = 'SET_CONTACT_INFO';
export const SET_SOCIAL_URLS = 'SET_SOCIAL_URLS';
export const SET_META_TAGS = 'SET_META_TAGS';
export const SET_HOSTNAME = 'SET_HOSTNAME';
export const SET_ECOMAIL_SETTINGS = 'ECOMAIL';

export const SET_FREE_DELIVERY_INFO = 'SET_FREE_DELIVERY_INFO';

export const LANG_COOKIE = 'lang';
export const CURRENCY_COOKIE = 'currency';
export const JBL_PRODUCT_VIEW_COOKIE = 'jbl_product_view_cookie';
export const DSI_VAT_VIEW_COOKIE = 'dsi_vat_view_cookie';
export const DSI_VOC_VIEW_COOKIE = 'dsi_voc_view_cookie';

export const REQUEST_REGISTER_USER = `${namespace}/REQUEST_REGISTER_USER`;
export const RECEIVE_REGISTER_USER_SUCCESS = `${namespace}/RECEIVE_REGISTER_USER_SUCCESS`;
export const RECEIVE_REGISTER_USER_FAILURE = `${namespace}/RECEIVE_REGISTER_USER_FAILURE`;

export const REQUEST_LOGIN_USER = `${namespace}/REQUEST_LOGIN_USER`;
export const RECEIVE_LOGIN_USER_SUCCESS = `${namespace}/RECEIVE_LOGIN_USER_SUCCESS`;
export const RECEIVE_LOGIN_USER_FAILURE = `${namespace}/RECEIVE_LOGIN_USER_FAILURE`;

export const OPEN_DIMMER = `${namespace}/OPEN_DIMMER`;
export const CLOSE_DIMMER = `${namespace}/CLOSE_DIMMER`;
export const SET_DIMMER_HEIGHT = `${namespace}/SET_DIMMER_HEIGHT`;

export const REQUEST_CREATED_ORDER = `${namespace}/REQUEST_CREATED_ORDER`;
export const RECEIVE_CREATED_ORDER_SUCCESS = `${namespace}/RECEIVE_CREATED_ORDER_SUCCESS`;
export const RECEIVE_CREATED_ORDER_ERROR = `${namespace}/RECEIVE_CREATED_ORDER_ERROR`;

export const OPEN_LOGIN_MODAL = `${namespace}/OPEN_LOGIN_MODAL`;
export const CLOSE_LOGIN_MODAL = `${namespace}/CLOSE_LOGIN_MODAL`;

export const SET_INVOICE_DATA = `${namespace}/SET_INVOICE_DATA`;
export const SET_CONTROL_INSTITUTE_DATA = `${namespace}/SET_CONTROL_INSTITUTE_DATA`;
export const SET_POINTS_AMOUNT_RATIO = `${namespace}/SET_POINTS_AMOUNT_RATIO`;

export const SET_TOP_MENU_BRANDS = `${namespace}/SET_TOP_MENU_BRANDS`;

export const REQUEST_SEARCH_TERM = `${namespace}/REQUEST_SEARCH_TERM`;
export const RECEIVE_SEARCH_TERM_SUCCESS = `${namespace}/RECEIVE_SEARCH_TERM_SUCCESS`;

export const REQUEST_PARTIAL_SEARCH_TERM = `${namespace}/REQUEST_PARTIAL_SEARCH_TERM`;
export const RECEIVE_PARTIAL_SEARCH_TERM_SUCCESS = `${namespace}/RECEIVE_PARTIAL_SEARCH_TERM_SUCCESS`;

export const REQUEST_SEARCH_TERM_ARTICLES = `${namespace}/REQUEST_SEARCH_TERM_ARTICLES`;
export const RECEIVE_SEARCH_TERM_ARTICLES_SUCCESS = `${namespace}/RECEIVE_SEARCH_TERM_ARTICLES_SUCCESS`;

export const REQUEST_PARTIAL_CATEGORY_SEARCH_TERM = `${namespace}/REQUEST_PARTIAL_CATEGORY_SEARCH_TERM`;
export const RECEIVE_PARTIAL_CATEGORY_SEARCH_TERM_SUCCESS = `${namespace}/RECEIVE_PARTIAL_CATEGORY_SEARCH_TERM_SUCCESS`;

export const SET_SEARCH_RESULTS_FILTERS = `${namespace}/SET_SEARCH_RESULTS_FILTERS`;

export const REQUEST_TOP_MENU_ITEMS = `${namespace}/REQUEST_TOP_MENU_ITEMS`;
export const ERROR_TOP_MENU_ITEMS = `${namespace}/ERROR_TOP_MENU_ITEMS`;

export const RECEIVE_SITEMAP_SUCCESS = `${namespace}/RECEIVE_SITEMAP_SUCCESS`;

export const SET_CURRENT_THEME = `${namespace}/SET_CURRENT_THEME`;
export const SET_SETTINGS_LOADED = `${namespace}/SET_SETTINGS_LOADED`;

// export const REQUEST_ADD_TO_FAVORITES = `${namespace}/REQUEST_ADD_TO_FAVORITES`;
// export const RECEIVE_ADD_TO_FAVORITES_SUCCESS = `${namespace}/RECEIVE_ADD_TO_FAVORITES_SUCCESS`;
// export const RECEIVE_ADD_TO_FAVORITES_ERROR = `${namespace}/RECEIVE_ADD_TO_FAVORITES_ERROR`;

export const SET_BREADCRUMB_VISIBLE = `${namespace}/SET_BREADCRUMB_VISIBLE`;

export const SET_NOT_FOUND = `${namespace}/SET_NOT_FOUND`;

export const SET_OTHER_TEXTS = `${namespace}/SET_OTHER_TEXTS`;

export const RECEIVE_BRAND_DETAIL_SUCCESS = `${namespace}/RECEIVE_BRAND_DETAIL_SUCCESS`;
export const RECEIVE_BRAND_CATEGORIES_SUCCESS = `${namespace}/RECEIVE_BRAND_CATEGORIES_SUCCESS`;

export const RECEIVE_PRICE_RETAIL_WHOLESALE_DISPLAY_TYPE_SUCCESS = `${namespace}/RECEIVE_PRICE_RETAIL_WHOLESALE_DISPLAY_TYPE_SUCCESS`;
export const RECEIVE_PRICE_VAT_DISPLAY_TYPE_SUCCESS = `${namespace}/RECEIVE_PRICE_VAT_DISPLAY_TYPE_SUCCESS`;
export const ADMIN_CONTENT_PATH = `${namespace}/ADMIN_CONTENT_PATH`;
