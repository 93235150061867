import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  HeaderLogo as HeaderSmallLogo,
  FlexRow,
  FlexColCenter,
  FlexCol,
  DsiSearchForm as SearchForm,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import HeaderCart from './HeaderCart';
import HeaderProfile from './HeaderProfile';
import { Navigation } from './Navigation';
import { DesktopWrapper } from '../_helpers/Default/ResponsiveWrappers';
import { HeaderFavourite } from './HeaderFavourite';
import { HeaderLangButton } from './HeaderLangButton';
import { getCurrencyFromLang } from '../../utilities';
import * as cookie from 'react-cookies';
import {
  DSI_VAT_VIEW_COOKIE,
  DSI_VOC_VIEW_COOKIE,
} from '../../containers/App/constants';
import { useState } from 'react';

interface Props {
  lang: string;
  items: ThenArg<typeof API.loadTree>;
  landingPages: any;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
  };
  toggleResponsiveCategoryMenu: any;
  handleSearchFocus: any;
  hideSearchResultsViewer: () => void;
  resultsDropdownVisible: boolean;
  searchTerm: string;
  handleSearchTermChange: (e: any) => void;
  redirectToSearchResultsPage: (e: any) => void;
  changeDimmerVisible: (newVal: boolean) => void;
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  user: any;
  isHome: boolean;
  headerArticles: any;
  clearSearchInput: () => void;
  cartPrice: number;
  cartCurrency: string;
  cartData: ThenArg<typeof API.getCart>;
  showActionLink: boolean;
  byId: any;
}

/*****************/

const StyledDesktopWrapper = styled(DesktopWrapper)`
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
`;

const HeaderWrapper = styled(FlexCol)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0 auto;
  width: 100%;
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  align-items: center;

  @media print {
    display: none;
  }

  ${({ theme }) => theme.mediab.l925`
      margin: 0;
  `}
`;

const UpperHeader = styled.div`
  border-bottom: ${({ theme }) => theme.borders.primary};
  width: 100%;
`;

const UpperHeaderWrapper = styled(FlexRow)`
  padding: ${rem(24)};
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};

  margin: 0 auto;
`;

const BottomHeader = styled(FlexRow)`
  padding: 0 ${rem(24)};
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
`;

const MiddleWrapper = styled(FlexColCenter)`
  width: 100%;
  max-width: ${rem(400)};
  margin: 0 ${rem(16)};

  ${({ theme }) => theme.mediab.l1300`
    max-width: ${rem(300)};
  `}
  ${({ theme }) => theme.mediab.l1150`
    max-width: ${rem(200)};
  `}
  ${({ theme }) => theme.mediab.l1050`
    max-width: ${rem(400)};
  `}
  ${({ theme }) => theme.mediab.l1050`
    max-width: ${rem(300)};
  `}
`;

const RightWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  max-width: ${rem(600)};
  margin-left: ${rem(16)};
  justify-content: flex-end;
  ${({ theme }) => theme.mediab.l1050`
    max-width: ${rem(200)};
    margin-right: ${rem(30)};
  `}
`;

function DesktopHeader({
  user,
  contact: { phone, email },
  handleSearchFocus,
  hideSearchResultsViewer,
  resultsDropdownVisible,
  searchTerm,
  handleSearchTermChange,
  redirectToSearchResultsPage,
  changeDimmerVisible,
  items,
  landingPages,
  partialSearchProducts,
  partialSearchProductsIsFetching,
  partialSearchCategories,
  partialSearchCategoriesIsFetching,
  dispatch,
  isHome,
  headerArticles,
  clearSearchInput,
  cartPrice,
  cartCurrency,
  lang,
  cartData,
  showActionLink,
  byId,
}: Props) {
  const logoSrc = '/images/icons/logo.svg';

  const isB2B = user && user.b2b;
  const [vocMoc, setVocMoc] = useState(
    cookie.load(DSI_VOC_VIEW_COOKIE) || 'voc',
  );
  const [vatView, setVatView] = useState(
    cookie.load(DSI_VAT_VIEW_COOKIE) || 'withVat',
  );
  const cookieProductVocView = cookie.load(DSI_VOC_VIEW_COOKIE);
  const cookieProductVatView = cookie.load(DSI_VAT_VIEW_COOKIE);
  React.useEffect(() => {
    if (cookieProductVocView) {
      setVocMoc(cookieProductVocView);
    }
    if (cookieProductVatView) {
      setVatView(cookieProductVatView);
    }
  }, [cookieProductVocView, cookieProductVatView]);
  const showMoc = isB2B ? vocMoc === 'moc' : true;
  const showWithVat = isB2B ? vatView === 'withVat' : true;

  return (
    <HeaderWrapper>
      <StyledDesktopWrapper>
        <UpperHeader>
          <UpperHeaderWrapper>
            <HeaderSmallLogo logo={logoSrc} />
            <MiddleWrapper>
              <SearchForm
                searchResults={{
                  productsResult: partialSearchProducts,
                  categoriesResult: partialSearchCategories,
                  articlesResult: [],
                }}
                redirectToSearchResultPage={redirectToSearchResultsPage}
                searchTerm={searchTerm}
                handleSearchTermChange={handleSearchTermChange}
                handleFocus={handleSearchFocus}
                hideResultViewer={hideSearchResultsViewer}
                resultsDropdownVisible={resultsDropdownVisible}
                partialSearchProductsIsFetching={
                  partialSearchProductsIsFetching
                }
                partialSearchCategoriesIsFetching={
                  partialSearchCategoriesIsFetching
                }
                clearSearchInput={clearSearchInput}
                partialSearchArticlesIsFetching={false}
                showMoc={showMoc}
                showWithVat={showWithVat}
              />
            </MiddleWrapper>
            <RightWrapper>
              <HeaderProfile user={user} dispatch={dispatch} />
              <HeaderFavourite />
              <HeaderCart
                numberOfProducts={2}
                totalPrice={cartPrice}
                currency={cartCurrency || getCurrencyFromLang(lang)}
                cartWidth={300}
                cartData={cartData}
                dispatch={dispatch}
                user={user}
              />
              <HeaderLangButton lang={lang} dispatch={dispatch} />
            </RightWrapper>
          </UpperHeaderWrapper>
        </UpperHeader>
        <BottomHeader>
          <Navigation
            showActionLink={showActionLink}
            user={user}
            lang={lang}
            changeDimmerVisible={changeDimmerVisible}
            items={items}
            landingPages={landingPages}
            isHome={isHome}
            navArticles={headerArticles}
            byId={byId}
          />
        </BottomHeader>
      </StyledDesktopWrapper>
    </HeaderWrapper>
  );
}

export default DesktopHeader;
