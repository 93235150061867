import { createSelector } from 'reselect';
import { prop } from '../../utilities';

const generalDomainSelector = state => state.general;

const authRegisterDomain = createSelector(
  generalDomainSelector,
  auth => auth.register,
);

export const isBreadcrumbVisibleSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'isBreadcrumbVisible', false),
);

const registrationDomain = createSelector(
  generalDomainSelector,
  authorization => authorization.register,
);

export const registrationErrorMessageSelector = createSelector(
  registrationDomain,
  register => register.error,
);

export const registrationSuccessMessageSelector = createSelector(
  registrationDomain,
  register => register.success,
);

export const registrationIsFetchingSelector = createSelector(
  authRegisterDomain,
  register => register.isFetching,
);

export const dimmerVisibilitySelector = createSelector(
  generalDomainSelector,
  general => general.dimmerVisible || false,
);

export const currentThemeSelector = createSelector(
  generalDomainSelector,
  general => general.currentTheme,
);

const orderDomainSelector = state => state.orders;

export const createdOrderIsFetchingSelector = createSelector(
  orderDomainSelector,
  substate => prop(substate, 'createdOrder.isFetching', true),
);

export const createdOrderDataSelector = createSelector(
  orderDomainSelector,
  substate => prop(substate, 'createdOrder.createdOrderData', null),
);

export const langChangedSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'langChanged', false),
);

export const hostnameSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'hostname', ''),
);

export const langSelector = createSelector(generalDomainSelector, substate =>
  prop(substate, 'lang', ''),
);

export const currencySelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'currency', ''),
);

export const loginModalVisibleSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'loginModalVisible', false),
);

const authDomainSelector = state => state.auth;

export const userSelector = createSelector(
  authDomainSelector,
  substate => substate.user,
);

export const contactInfoSelector = createSelector(
  generalDomainSelector,
  substate =>
    prop(substate, 'contactInfo', { contactEmail: '', contactTel: '' }),
);

export const socialUrlsSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'socialUrls', {}),
);

export const invoiceDataSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'invoiceData', {}),
);

export const controlInstituteDataSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'controlInstituteData', {}),
);

export const pointsAmountRatioSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'pointsAmountRatio', 0),
);

export const generalFreeDeliveryInfoSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'freeDeliveryInfo', {}),
);

export const topMenuItemsSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'menuItems.topMenuItems', []),
);

export const topMenuItemsIsFetchingSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'menuItems.isFetching', false),
);

export const topMenuBrandsSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'brands', []),
);

export const searchResultsIsFetchingArticlesSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'search.articles.isFetching', false),
);

export const searchResultsSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'search.results.products', []),
);

export const searchResultsIsFetchingSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'search.isFetching', false),
);

export const searchResultsOffsetSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'search.offset', 0),
);

export const partialSearchProductsSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'search.partialResults.products', null),
);

export const partialSearchProductsIsFetchingSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'search.partialResults.isFetching', false),
);

export const partialSearchCategoriesSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'search.partialResults.categories', null),
);

export const partialSearchCategoriesIsFetchingSelector = createSelector(
  generalDomainSelector,
  substate =>
    prop(substate, 'search.partialResults.isFetchingCategories', false),
);

export const footerDataSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'footerData.sitemap_tree', []),
);

export const headerDataSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'headerData.sitemap_tree', []),
);

export const searchResultsArticlesSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'search.articles.articles', []),
);

export const brandDetailSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'brandDetail', null),
);

export const brandCategoriesSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'brandCategories', null),
);

export const priceDisplaySelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'priceDisplaySelector', null),
);

export const ecomailSettingsSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'ecomailSettings', null),
);
