import React, { useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  FlexRow,
  FlexCol,
  DsiNavigationItem as NavigationItem,
  DsiTags as Tags,
  ItemType,
  DsiNavigationDropdownContent as NavigationDropdownContent,
  DsiSvgIcon as SvgIcon,
  DsiFirstNavigationItems as FirstNavigationItems,
  OutsideClick,
  TextP,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { Link } from 'react-router';
import { __, __r } from 'react-i18n/lib';
import { resolveCategoryUrl } from 'eshop-defaults/lib/utilities/selectors';
import {
  resolveCategoryName,
  resolveCategoryUrlString,
} from '../../utilities/category';
import { prop } from '../../utilities';

export interface Props {
  user?: any;
  items: ThenArg<typeof API.loadTree>;
  landingPages: any;
  changeDimmerVisible: (newVal: boolean) => void;
  isHome: boolean;
  navArticles: any;
  showActionLink?: boolean;
  lang: string;
  byId: any;
}

function Navigation({
  items,
  landingPages,
  changeDimmerVisible,
  user,
  isHome,
  navArticles,
  showActionLink,
  lang,
  byId,
}: Props) {
  const [isOpen, setIsOpen] = useState(isHome);
  const closeNav = () => {
    setIsOpen(false);
    changeDimmerVisible(false);
  };

  const toggleNav = () => {
    if (isHome) {
      setIsOpen(false);
    } else {
      setIsOpen(!isOpen);
      changeDimmerVisible(!isOpen);
    }
  };

  const actionLink = navArticles.find(
    n => n.uniqid && n.uniqid.includes('AKCIE'),
  );
  const saleoutLink = navArticles.find(
    n => n.uniqid && n.uniqid.includes('VYPREDAJ'),
  );

  const recycledLink =
    items && items.find(c => prop(c, 'category_number') === 'BAZAR');

  const dropdownChildren = (
    <FirstHoverWrapper>
      <FirstItemsWrapper>
        <NavigationItemsWrapper>
          {items &&
            items.map(category => {
              if (prop(category, 'category_number') === 'BAZAR') {
                return null;
              }

              return (
                <FirstNavigationItems
                  onClickDropdown={toggleNav}
                  isOpenDropdown={isOpen}
                  category={category}
                  level={-1}
                  byId={byId}
                />
              );
            })}
        </NavigationItemsWrapper>
        <Sales>
          {showActionLink && actionLink && (
            <ActionLink to={`/${actionLink.url}`}>
              <Stock>
                <Tags
                  isSale={true}
                  tagWidth={34}
                  tagHeight={34}
                  style={{
                    margin: 0,
                  }}
                />
                <Discount>{actionLink.name}</Discount>
              </Stock>
            </ActionLink>
          )}
          {saleoutLink && (
            <SaleoutLink to={saleoutLink.url}>
              <Stock>
                <TextP>{saleoutLink.name}</TextP>
              </Stock>
            </SaleoutLink>
          )}
          {recycledLink && (
            <ActionLink
              to={
                prop(recycledLink, 'publish.0.redirect_url')
                  ? prop(recycledLink, 'publish.0.redirect_url')
                  : recycledLink.weburl
                  ? recycledLink.weburl
                  : resolveCategoryUrl(
                      recycledLink.category_id,
                      prop(recycledLink, 'publish.0.url') ||
                        recycledLink.url ||
                        'url',
                      'CATEGORYTEXT/URL',
                    )
              }
            >
              <Stock>
                <Tags
                  isSecondHand={true}
                  tagWidth={34}
                  tagHeight={34}
                  style={{
                    margin: 0,
                  }}
                />
                <Discount>{resolveCategoryName(recycledLink)}</Discount>
              </Stock>
            </ActionLink>
          )}

          {landingPages?.length ? (
            landingPages.map(landingPage => {
              return (
                <ActionLink
                  to={`${__r('routes:akcie', '/akcie')}/${
                    landingPage.id
                  }/${landingPage.url.replace(/^\/|\/$/g, '')}`}
                >
                  <Stock>
                    <Tags
                      isSale={true}
                      tagWidth={34}
                      tagHeight={34}
                      style={{
                        margin: 0,
                      }}
                    />
                    <Discount>{landingPage.name}</Discount>
                  </Stock>
                </ActionLink>
              );
            })
          ) : (
            <></>
          )}
        </Sales>
      </FirstItemsWrapper>
    </FirstHoverWrapper>
  );

  return (
    <Wrapper>
      <StyledOutsideClick handleFunction={!isHome ? closeNav : () => {}}>
        <NavWrapper>
          <FirstItem
            // tslint:disable-next-line:jsx-no-lambda
            onClick={toggleNav}
            type={ItemType.PRIMARY}
            title={<span>{__('Vyberte si')}</span>}
            icon={''}
          />
          {actionLink && showActionLink && (
            <ActionLinkHeader to={`/${actionLink.url}`}>
              <Stock>
                <SvgIcon
                  icon={IconType.saleout}
                  fill={'#FF3054'}
                  cursor="pointer"
                  width={40}
                  height={40}
                />
                <Recycled>{actionLink.name}</Recycled>
              </Stock>
            </ActionLinkHeader>
          )}
          {recycledLink && (
            <ActionLinkHeader
              to={
                prop(recycledLink, 'publish.0.redirect_url')
                  ? prop(recycledLink, 'publish.0.redirect_url')
                  : recycledLink.weburl
                  ? recycledLink.weburl
                  : resolveCategoryUrl(
                      recycledLink.category_id,
                      prop(recycledLink, 'publish.0.url') ||
                        recycledLink.url ||
                        'url',
                      'CATEGORYTEXT/URL',
                    )
              }
            >
              <Stock>
                <SvgIcon
                  icon={IconType.recycled}
                  fill={'#FF3054'}
                  cursor="pointer"
                  width={40}
                  height={40}
                />
                <Recycled>
                  {prop(recycledLink, 'publish.0.category_name') ||
                    recycledLink.category_name}
                </Recycled>
              </Stock>
            </ActionLinkHeader>
          )}

          {landingPages?.length ? (
            landingPages.map(landingPage => {
              return (
                <ActionLinkHeader
                  to={`${__r('routes:akcie', '/akcie')}/${
                    landingPage.id
                  }/${landingPage.url.replace(/^\/|\/$/g, '')}`}
                >
                  <Stock>
                    <SvgIcon
                      icon={IconType.saleout}
                      fill={'#FF3054'}
                      cursor="pointer"
                      width={40}
                      height={40}
                    />
                    <Recycled>{landingPage.name}</Recycled>
                  </Stock>
                </ActionLinkHeader>
              );
            })
          ) : (
            <></>
          )}

          <RightNav>
            <SecondaryItems>
              {items &&
                items
                  .filter((i: any) => i.is_top)
                  .map((i: any) => {
                    return (
                      <NavigationItem
                        key={i.category_id}
                        onClick={closeNav}
                        url={resolveCategoryUrl(
                          i.category_id,
                          resolveCategoryUrlString(i, byId),
                          'CATEGORYTEXT/URL',
                        )}
                        type={ItemType.SECONDARY}
                        title={resolveCategoryName(i)}
                        icon={
                          i.category_number
                            ? i.category_number.includes('AUDIO')
                              ? 'audio'
                              : i.category_number.includes('KITCHEN')
                              ? 'kitchen'
                              : 'home'
                            : 'home'
                        }
                      />
                    );
                  })}
            </SecondaryItems>{' '}
            <TertiaryItems>
              {navArticles &&
                navArticles.map(article => {
                  if (
                    article.uniqid &&
                    (article.uniqid.includes('AKCIE') ||
                      article.uniqid.includes('VYPREDAJ'))
                  ) {
                    return null;
                  }
                  return (
                    <NavigationItem
                      key={article.id}
                      onClick={closeNav}
                      outsideLink={article.link}
                      url={`/${article.url}`}
                      type={ItemType.TERTIARY}
                      title={article.name}
                    />
                  );
                })}

              {/* <NavigationItem
                onClick={closeNav}
                url={__r('routes:blog', '/blog')}
                type={ItemType.TERTIARY}
                title={'Blog'}
              />
              <NavigationItem
                onClick={closeNav}
                url="/o-nakupe"
                type={ItemType.TERTIARY}
                title={'Všetko o nákupe'}
              />
              <NavigationItem
                onClick={closeNav}
                url="/servis"
                type={ItemType.TERTIARY}
                title={'Servis'}
              />{' '} */}
            </TertiaryItems>
          </RightNav>
          {isHome ? (
            <NavigationDropdownContent
              fromTop={user ? 164 : 164}
              children={dropdownChildren}
            />
          ) : (
            isOpen && (
              <NavigationDropdownContent
                fromTop={user ? 164 : 164}
                children={dropdownChildren}
              />
            )
          )}
        </NavWrapper>
      </StyledOutsideClick>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const StyledOutsideClick = styled(OutsideClick)`
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
`;

const NavWrapper = styled(FlexRow)`
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
`;

const RightNav = styled(FlexRowCenterVertical)`
  width: 100%;
  justify-content: space-between;
`;

const TertiaryItems = styled(FlexRowCenterVertical)``;

const SecondaryItems = styled(FlexRowCenterVertical)`
  padding: 0 ${rem(30)};
  background-color: ${({ theme }) => theme.colors.navSecondary};
`;

const FirstItem = styled(NavigationItem)`
  width: ${rem(328)};
  min-width: ${rem(328)};
`;

const FirstHoverWrapper = styled(FlexRow)`
  background-color: white;

  ${({ theme }) => theme.mediab.l1050`
    max-width: ${rem(900)};
  `}
`;

const Sales = styled(FlexCol)`
  width: 100%;
  padding: ${rem(14)} 0;
  border-top: ${({ theme }) => theme.borders.primary};
`;

const ActionLink = styled(Link)`
  color: ${({ theme }) => theme.colors.red};
`;

const ActionLinkHeader = styled(ActionLink)`
  margin-top: auto;
  margin-bottom: auto;
`;

const SaleoutLink = styled(ActionLink)`
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-top: ${rem(16)};
`;

const Stock = styled(FlexRow)`
  padding: ${rem(8)} 0 0 ${rem(24)};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${rem(16)};
  display: flex;
  align-items: center;
`;

const Discount = styled(TextP)`
  margin-left: ${rem(12)};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-bottom: 10px;
`;

const Recycled = styled(TextP)`
  margin-left: ${rem(12)};
  color: ${({ theme }) => theme.colors.textPrimary};
  white-space: nowrap;
`;

const FirstItemsWrapper = styled(FlexCol)`
  box-shadow: ${({ theme }) => theme.boxShadow.secondary};
  width: ${rem(304)};
  padding: ${rem(20)} 0 0 0;

  border-radius: 0 0 0 ${rem(2)};
  color: black;
`;

const NavigationItemsWrapper = styled.div``;

export { Navigation };
