import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { rem } from 'polished';
import API, { ThenArg } from '../../services/API';
import { __, __r } from 'react-i18n';
import { withRouter, WithRouterProps } from 'react-router';
import { LogoutButton } from './MyAccount';
import { connectSsr } from 'ssr-service';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import { StyledTitle } from '../../components/MyAccount/MyDashboard';
import { resolveLangByHostname } from '../../configureTrans';
import { hostnameSelector, langSelector, userSelector } from '../App/selectors';
import { PasswordChangeBlock } from 'eshop-defaults';
import { UserSettingsBlock } from 'eshop-defaults';
import { loadUserData, logoutUser } from './actions';
import { userInfoSelector } from './selectors';
import { customerInfoSelector, fetchCustomerDetails } from './myAccountSlice';
import { RemoveAccountBlock } from 'eshop-defaults';

const Block = styled.div`
  background: rgba(65, 65, 65, 0.02);
  padding: ${rem(10)} ${rem(15)} ${rem(10)} ${rem(15)};
  margin-bottom: ${rem(15)};
`;

interface MySettingsPropsIO {
  user: any;
  hostname: string;
  lang: string;
  customerInfo: ThenArg<typeof API.getCustomerDetails>;
  dispatch: any;
}

interface State {}

class MySettings extends React.Component<
  MySettingsPropsIO & WithRouterProps,
  State
> {
  public static async getInitialProps({ dispatch }) {
    dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_SETTINGS));
    await Promise.all([dispatch(fetchCustomerDetails())]);
    return;
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  public onPasswordChange = async (password, oldPassword, confirmPassword) => {
    const { user, lang, hostname } = this.props;
    await API.changePassword(
      user.id,
      {},
      {
        new_password: password,
        current_password: oldPassword,
        password_check: confirmPassword,
        lang,
        domain_id: resolveLangByHostname(hostname),
      },
    );
  };

  public onNewsletterChange = async newsletterAccept => {
    const { customerInfo } = this.props;
    if (customerInfo) {
      await API.updateCustomerSettings(
        customerInfo.id,
        {},
        {
          ...customerInfo,
          newsletter_accept: newsletterAccept,
        },
      );
    }
  };

  public onDeleteAccount = async () => {
    const { customerInfo } = this.props;
    await API.deleteRequestCustomer(customerInfo.id);
    this.props.dispatch(logoutUser());
  };

  public render() {
    const {} = this.state;
    return (
      <>
        <Block>
          {' '}
          <PasswordChangeBlock onPasswordChange={this.onPasswordChange} />{' '}
        </Block>
        {!this.props?.user?.b2b ? (
          <Block>
            {' '}
            <UserSettingsBlock
              onNewsletterChange={this.onNewsletterChange}
              customerInfo={this.props.customerInfo}
            />{' '}
          </Block>
        ) : (
          <></>
        )}

        <Block>
          <RemoveAccountBlock deleteAccount={this.onDeleteAccount} />
        </Block>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: userSelector(state),
    lang: langSelector(state),
    hostname: hostnameSelector(state),
    customerInfo: customerInfoSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MySettings' })(withRouter(MySettings)),
);
